.App {
  /* background-color: #131f2b;
  background-image: url(./data/photos/background-wall.jpeg);
  background-image: url(./data/photos/wall-2-sm.jpeg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #090909;
  min-height: 100vh;
}

/* html{scroll-behavior:smooth} */