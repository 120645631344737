@keyframes runningDesktop {
    from {
      transform: translateX(60%);
    }
  
    to {
      transform: translateX(-50%);
    }
  }
  @keyframes runningDesktopRtl {
    from {
      transform: translateX(-60%);
    }
  
    to {
      transform: translateX(50%);
    }
  }
  @keyframes runningMobile {
    from {
      transform: translateX(3%);
    }
  
    to {
      transform: translateX(-55%);
    }
  }
  @keyframes runningMobileRtl {
    from {
      transform: translateX(-3%);
    }
  
    to {
      transform: translateX(55%);
    }
  }
  .runningText {
    /* display: flex; */
    width: max-content;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin: auto !important;
    animation-direction: alternate;
  }
  .runningTextRtl {
    /* display: flex; */
    width: max-content;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin: auto !important;
    animation-direction: alternate;
  }
  @media screen and (min-width: 600px) {
      .runningText {
        animation-name: runningDesktop;
        font-size: 1.3em !important;
      }
      .runningTextRtl {
        animation-name: runningDesktopRtl;
        font-size: 1.3em !important;
      }
    }
  @media screen and (max-width: 600px) {
  .runningText {
    animation-name: runningMobile;
  }
  .runningTextRtl {
    animation-name: runningMobileRtl;
  }
}